import { createRouter, createWebHistory } from 'vue-router';
import { authBeforeEach } from '@bridge-immo/common';
import { PageNotFound } from '@bridge-immo/ui';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/confirm/:token',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "reset-confirm" */ '../views/Confirm.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(authBeforeEach);

export default router;
