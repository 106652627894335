import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/index.css';
import '@bridge-immo/ui/dist/ui.css';

import { DefaultApolloClient } from '@vue/apollo-composable';
import { getClient } from '@bridge-immo/common';

createApp(App)
  .use(router)
  .use(store)
  .provide(DefaultApolloClient, getClient())
  .mount('#app');
